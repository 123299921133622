import React, { useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'

import Wrapper from '../components/Wrapper'
import InputBox from '../components/Form/InputBox'
import CustomAlert from '../components/Alerts'
import InvisibleRepatcha from '../components/InvisibleRepatcha'

import { forgotPasswordValidations } from '../validations'
import { DOWNLOADER } from '../data/constants'

import { postData as forgotPassword } from '../reducers/app'
import { FORGOT_PASSWORD } from '../data/apis'

import { UserContext } from '../components/UserContext'
import useForm from '../hooks/useForm'
import SEO from '../components/SEO'

const init = {
  email: '',
  captcha: true,
}

let forgotPasswordForm

const ForgotPassword = () => {
  const { isLoggedIn } = useContext(UserContext)
  const { values, resetForm, errors, handleSubmit, handleOnChange } = useForm(
    init,
    forgotPasswordValidations,
    val => {
      forgotPasswordForm = val
      return forgotPasswordForm
    }
  )
  useEffect(() => {
    if (isLoggedIn) {
      navigate(DOWNLOADER)
    }
  }, [isLoggedIn])

  const {
    forgotpassword: { loading, loaded, data, error },
  } = useSelector(state => state.app)
  const dispatch = useDispatch()

  const submitForm = token => {
    const formData = {
      ...forgotPasswordForm,
      token,
    }
    dispatch(forgotPassword(FORGOT_PASSWORD, formData))
    const { grecaptcha } = window
    grecaptcha.reset()
    resetForm()
  }

  return (
    <>
      <SEO page="forgotPassword" />
      <Wrapper title="Forgot Password">
        <div className="col-sm-6">
          <div className="cboxEraForm">
            <form onSubmit={handleSubmit}>
              <InputBox
                name="email"
                label="Email"
                type="email"
                placeholder="Your registered email.."
                value={values.email}
                onChangeInput={handleOnChange}
                error={errors.email}
              />
              <div className="row">
                <InvisibleRepatcha onResolved={submitForm} />
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <Button type="submit" color="success" disabled={loading}>
                    {loading ? 'Please wait..' : 'Submit'}
                  </Button>
                </div>
              </div>
            </form>
            {loaded && !loading && (
              <CustomAlert type={`${data && data.msg ? 'success' : 'danger'}`}>
                <span>{(data && data.msg) || error}</span>
              </CustomAlert>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default ForgotPassword
